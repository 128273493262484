import React from 'react';
import styles from './WomenTopwearBanner.module.css'; // Importing the modular CSS

const WomenTopwearBanner = () => {
  return (
    <div className={styles.container}>
      <div className={styles.textContainer}>
        <p className={styles.text}>Women's Topwear </p>
        <h2>Women's &gt; Topwear</h2>
      </div>
      <div className={styles.imageContainer}>
        <img src="/images/WomenTopwearImg.jpg" alt="Placeholder" className={styles.image} />
      </div>
    </div>
  );
};

export default WomenTopwearBanner;

import React from 'react';
import styles from './MensTopwearBanner.module.css'; // Importing the modular CSS

const MensTopwearBanner = () => {
  return (
    <div className={styles.container}>
      <div className={styles.textContainer}>
        <p className={styles.text}>Men's Topwear </p>
        <h2>Men's &gt; Topwear</h2>
      </div>
      <div className={styles.imageContainer}>
        <img src="/images/MensTopwearImg.jpg" alt="Placeholder" className={styles.image} />
      </div>
    </div>
  );
};

export default MensTopwearBanner;

import React, { useEffect, useState } from 'react';
import styles from './MensTopwear.module.css';
import MensTopwearBanner from './MensTopwearBanner';
import LoadingSpinner from '../LoadingSpinner';

// Helper function to slugify the product name
const slugify = (name) => {
  return name
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, '-')
    .replace(/(^-|-$)/g, '');
};

// Get base product URL from environment variables
const BASE_URL = process.env.REACT_APP_BASE_PRODUCT_URL || 'https://myshopprime.com';

// Helper function to generate the full URL based on the base URL, slug, and id
const generateProductLink = (name, id) => {
  const slug = slugify(name);
  return `${BASE_URL}/product/${slug}/${id}`;
};

const Card = React.memo(({ id, image, name, subtitle, new_price, rating }) => {
  const productLink = generateProductLink(name, id); // Generate the complete link

  return (
    <div className={styles.card}>
      <a href={productLink} target="_blank" rel="noopener noreferrer">
        <img src={image} alt={name} className={styles.cardImage} loading="lazy" />
        <h3 className={styles.cardTitle}>{name}</h3>
        <p className={styles.cardSubtitle}>{subtitle}</p>
        <div className={styles.cardPrice}>
        <p className={styles.ratingButton}>
            {rating} ⭐
        </p>
          <p className={styles.newPrice}>₹{new_price}</p>
        </div>
      </a>
    </div>
  );
});

const MensTopwear = () => {
  const [popularItems, setPopularItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://affordly-backend.vercel.app';

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/menstopwearitems`);
        if (!response.ok) throw new Error('Network response was not ok');
        const data = await response.json();
        setPopularItems(data);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(true); // Set error state to true
      } finally {
        setLoading(false); // Always set loading to false after fetching
      }
    };

    fetchData();
  }, []);

  // Conditional rendering: show spinner if loading or if there's an error
  if (loading || error) {
    return <LoadingSpinner />;
  }

  return (
    <>
    <MensTopwearBanner></MensTopwearBanner>
    <section className={styles.section}>
      <h1 className={styles.heading}>Men's Topwear</h1>
      <div className={styles.cardContainer}>
        {popularItems.length > 0 ? (
          popularItems.map((card) => (
            <Card
              key={card.id}
              id={card.id}
              image={card.image}
              name={card.name}
              subtitle={card.subtitle}
              rating={card.rating}
              new_price={card.new_price}
            />
          ))
        ) : (
          <p>No items found</p>
        )}
      </div>
    </section>
    </>
  );
};

export default MensTopwear;

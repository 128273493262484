import React, { useEffect, useState } from 'react';
import styles from './Mens.module.css';
import LoadingSpinner2 from '../LoadingSpinner2';

// Helper function to slugify the product name
const slugify = (name) => {
  return name
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, '-')
    .replace(/(^-|-$)/g, '');
};

// Get base product URL from environment variables
const BASE_URL = process.env.REACT_APP_BASE_PRODUCT_URL || 'https://myshopprime.com';

// Helper function to generate the full URL based on the base URL, slug, and id
const generateProductLink = (name, id) => {
  const slug = slugify(name);
  return `${BASE_URL}/product/${slug}/${id}`;
};

const Card = React.memo(({ id, image, name, subtitle, new_price, rating }) => {
  const productLink = generateProductLink(name, id); // Generate the complete link

  return (
    <div className={styles.card}>
      <a href={productLink} target="_blank" rel="noopener noreferrer">
        <img src={image} alt={name} className={styles.cardImage} loading="lazy" />
        <h3 className={styles.cardTitle}>{name}</h3>
        <p className={styles.cardSubtitle}>{subtitle}</p>
        <div className={styles.cardPrice}>
          <p className={styles.ratingButton}>
            {rating} ⭐
          </p>
          <p className={styles.newPrice}>₹{new_price}</p>
        </div>
      </a>
    </div>
  );
});

const Mens = () => {
  const [popularItems, setPopularItems] = useState([]);
  const [visibleItems, setVisibleItems] = useState(12); // Start by rendering 12 items
  const [loading, setLoading] = useState(false); // Loading state for fetching more items
  const [error, setError] = useState(null); // Error state

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://affordly-backend.vercel.app';

  // Fetch data from the server
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true); // Show spinner while loading
        const response = await fetch(`${API_BASE_URL}/mensitems`);
        if (!response.ok) throw new Error('Network response was not ok');
        const data = await response.json();
        setPopularItems(data);
      } catch (error) {
        setError(true); // Set error state
      } finally {
        setLoading(false); // Stop loading when done
      }
    };

    fetchData();
  }, []);

  // Scroll event listener to load more items when the user reaches the bottom
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const windowHeight = window.innerHeight;
      const docHeight = document.documentElement.scrollHeight;

      // Check if the user has reached the bottom of the page
      if (windowHeight + scrollTop >= docHeight && !loading && visibleItems < popularItems.length) {
        setLoading(true); // Show spinner and fetch more items
        setTimeout(() => {
          setVisibleItems((prevVisibleItems) => prevVisibleItems + 12); // Load 12 more items
          setLoading(false); // Stop showing spinner after items are loaded
        }, 500); // Simulate loading time
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll); // Cleanup the event listener
  }, [loading, visibleItems, popularItems.length]);


  // Show error message if there's an error
  if (error) {
    return <p>Error loading products</p>;
  }


  return (
    <section className={styles.section}>
      <h1 className={styles.heading}>Men's Clothing</h1>
      <div className={styles.cardContainer}>
        {popularItems.slice(0, visibleItems).map((card) => (
          <Card
            key={card.id}
            id={card.id}
            image={card.image}
            name={card.name}
            subtitle={card.subtitle}
            rating={card.rating}
            new_price={card.new_price}
          />
        ))}
      </div>
      {loading && (
          <div style={{ textAlign: 'center', margin: '20px 0' }}>
            <LoadingSpinner2 />
          </div>
        )} {/* Spinner shown only when loading more */}
    </section>
  );
};

export default Mens;

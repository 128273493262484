import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import About from './components/About';
import Contact from './components/Contact';
import './App.css';
import { Analytics } from "@vercel/analytics/react"

// Shop Page
import ShopPage from './pages/ShopPage';

// Mens components
import MensClothingBanner from './components/mens/MensClothingBanner';
import Mens from './components/mens/Mens';
import MensTopwear from './components/mens/MensTopwear';
import MensBottomwear from './components/mens/MensBottomwear';
import MensFootwear from './components/mens/MensFootwear';


// Womens components
import Women from './components/women/Women';
import WomenClothingBanner from './components/women/WomenClothingBanner';
import WomenTopwear from './components/women/WomenTopwear';
import WomenBottomwear from './components/women/WomenBottomwear';
import WomenFootwear from './components/women/WomenFootwear';

// Accessories components
import AccessoriesBanner from './components/accessories/AccessoriesBanner';
import Accessories from './components/accessories/Accessories';

// Electronics components
import Hero2 from './components/electronics/Hero2';
import Electronics from './components/electronics/Electronics';

// All Products component
import AllProducts from './components/allproducts/AllProducts';

function App() {
  return (
    <>
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<ShopPage />} />
        <Route path="/shop" element={<ShopPage />} />
        <Route path="/men" element={<MensPage />} />
        <Route path="/men/topwear" element={<MensTopwear />} />
        <Route path="/men/bottomwear" element={<MensBottomwear />} />
        <Route path="/men/footwear" element={<MensFootwear />} />
        <Route path="/women" element={<WomensPage />} />
        <Route path="/women/topwear" element={<WomenTopwear />} />
        <Route path="/women/bottomwear" element={<WomenBottomwear />} />
        <Route path="/women/footwear" element={<WomenFootwear />} />
        <Route path="/accessories" element={<AccessoriesPage />} />
        <Route path="/electronics" element={<ElectronicsPage />} />
        <Route path="/allproducts" element={<AllProducts />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <Footer />
    </Router>
    <Analytics></Analytics>
    </>
  );
}



const MensPage = () => (
  <div>
    <MensClothingBanner />
    <Mens />
  </div>
);

const WomensPage = () => (
  <div>
    <WomenClothingBanner/>
    <Women/>
  </div>
)

const AccessoriesPage = () => (
  <div>
    <AccessoriesBanner />
    <Accessories />
    {/* Add other Accessories components if needed */}
  </div>
);

const ElectronicsPage = () => (
  <div>
    <Hero2 />
    <Electronics />
    {/* Add other Electronics components if needed */}
  </div>
);

export default App;

import React, { useState, useEffect } from 'react';
import styles from './Header.module.css';
import { Link } from 'react-router-dom';

const Header = () => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isDropdownOpenW, setIsDropdownOpenW] = useState(false);
    const [isDropdownOpenHam, setIsDropdownOpenHam] = useState(false);
    const [isDropdownOpenWHam, setIsDropdownOpenWHam] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1280);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const toggleDropdown = () => setIsDropdownOpen((prev) => !prev);
    const toggleDropdownW = () => setIsDropdownOpenW((prev) => !prev);
    const toggleDropdownHam = () => setIsDropdownOpenHam((prev) => !prev);
    const toggleDropdownWHam = () => setIsDropdownOpenWHam((prev) => !prev);
    const toggleMobileMenu = () => setIsMobileMenuOpen((prev) => !prev);

    useEffect(() => {
        const handleResize = () => {
            const isMobileScreen = window.innerWidth <= 1280;
            setIsMobile(isMobileScreen);

            // Automatically close the mobile menu when the screen size increases beyond 1200px
            if (!isMobileScreen) {
                setIsMobileMenuOpen(false);
            }
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        // Updated from <nav> to <header> for semantic SEO
        <header className={styles.navbar}>
            {/* Updated from <div> to <section> for semantic SEO */}
            <section className={styles.navbarLeft}>
                {/* Added aria-label for better accessibility */}
                <a href="/" className={styles.logo} aria-label="Home - Logo">
                    <img src="/logo2.png" alt="Logo" />
                </a>
            </section>

            {isMobile ? (
                <>
                    {/* Added aria-label for hamburger menu for accessibility */}
                    <div
                        className={`${styles.hamburger} ${isMobileMenuOpen ? styles.open : ''}`}
                        onClick={toggleMobileMenu}
                        aria-label="Mobile menu toggle"
                    >
                        <span className={styles.bar1}></span>
                        <span className={styles.bar2}></span>
                        <span className={styles.bar3}></span>
                    </div>
                    {isMobileMenuOpen && (
                        <section className={styles.mobileMenu}>
                            <Link to="/shop">Shop</Link>
                            <div className={styles.dropdownContainer}>
                                <div className={styles.dropDownText}>
                                    <Link to="/men">Men</Link>
                                    <span
                                        onClick={toggleDropdownHam}
                                        className={`${styles.dropdownButton} ${isDropdownOpenHam ? styles.open : ''}`}
                                    >
                                        <button className={styles.drpBtn}>▼</button>
                                    </span>
                                </div>
                                {isDropdownOpenHam && (
                                    <ul className={`${styles.mobileDropdownMenu} ${styles.open}`}>
                                        {/* Wrapped links in <li> to improve SEO */}
                                        <li><Link to="/men/topwear">Topwear</Link></li>
                                        <li><Link to="/men/bottomwear">Bottomwear</Link></li>
                                        <li><Link to="/men/footwear">Footwear</Link></li>
                                    </ul>
                                )}
                            </div>
                            <div className={styles.dropdownContainer}>
                                <div className={styles.dropDownText}>
                                    <Link to="/women">Women</Link>
                                    <span
                                        onClick={toggleDropdownWHam}
                                        className={`${styles.dropdownButton} ${isDropdownOpenWHam ? styles.open : ''}`}
                                    >
                                        <button className={styles.drpBtn}>▼</button>
                                    </span>
                                </div>
                                {isDropdownOpenWHam && (
                                    <ul className={`${styles.mobileDropdownMenuW} ${styles.open}`}>
                                        {/* Wrapped links in <li> to improve SEO */}
                                        <li><Link to="/women/topwear">Topwear</Link></li>
                                        <li><Link to="/women/bottomwear">Bottomwear</Link></li>
                                        <li><Link to="/women/footwear">Footwear</Link></li>
                                    </ul>
                                )}
                            </div>
                            <Link to="/accessories">Accessories</Link>
                            <Link to="/electronics">Electronics</Link>
                            <Link to="/allproducts">All Products</Link>
                            {/* Added aria-label for accessibility */}
                            <a
                                href='https://myshopprime.com/tarunkumar23/pzy1czq'
                                target="_blank"
                                rel="noopener noreferrer"
                                aria-label="Visit our Glowroad Shop"
                            >
                                <button className={styles.shopBtn}>Visit Our Glowroad Shop</button>
                            </a>
                        </section>
                    )}
                </>
            ) : (
                <>
                    {/* Updated from <div> to <section> for semantic SEO */}
                    <section className={styles.navbarMiddle}>
                        {/* Wrapped links in <ul> and <li> for improved SEO */}
                        <ul className={styles.navMenu}>
                            <li><Link to="/shop">Shop</Link></li>
                            <li className={styles.dropdown}>
                                <div className={styles.dropdownHeader}>
                                    <Link to="/men">Men</Link>
                                    <span
                                        onClick={toggleDropdown}
                                        className={`${styles.dropdownButton} ${isDropdownOpen ? styles.open : ''}`}
                                    >
                                        <button className={styles.drpBtn}>▼</button>
                                    </span>
                                </div>
                                {isDropdownOpen && (
                                    <ul className={styles.dropdownMenu}>
                                        <Link to="/men/topwear"><li>Topwear</li></Link>
                                        <Link to="/men/bottomwear"><li>Bottomwear</li></Link>
                                        <Link to="/men/footwear"><li>Footwear</li></Link>
                                    </ul>
                                )}
                            </li>
                            <li className={styles.dropdown}>
                                <div className={styles.dropdownHeader}>
                                    <Link to="/women">Women</Link>
                                    <span
                                        onClick={toggleDropdownW}
                                        className={`${styles.dropdownButton} ${isDropdownOpenW ? styles.open : ''}`}
                                    >
                                        <button className={styles.drpBtn}>▼</button>
                                    </span>
                                </div>
                                {isDropdownOpenW && (
                                    <ul className={styles.dropdownMenu}>
                                        <Link to="/women/topwear"><li>Topwear</li></Link>
                                        <Link to="/women/bottomwear"><li>Bottomwear</li></Link>
                                        <Link to="/women/footwear"><li>Footwear</li></Link>
                                    </ul>
                                )}
                            </li>
                            <li><Link to="/accessories">Accessories</Link></li>
                            <li><Link to="/electronics">Electronics</Link></li>
                            <li><Link to="/allproducts">All Products</Link></li>
                        </ul>
                    </section>
                    {/* Updated from <div> to <section> for semantic SEO */}
                    <section className={styles.navbarRight}>
                        {/* Added aria-label for accessibility */}
                        <a
                            href='https://myshopprime.com/tarunkumar23/pzy1czq'
                            target="_blank"
                            rel="noopener noreferrer"
                            aria-label="Visit our Glowroad Shop"
                        >
                            <button className={styles.shopBtn}>Visit Our Glowroad Shop</button>
                        </a>
                    </section>
                </>
            )}
        </header>
    );
}

export default Header;

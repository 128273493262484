import React from 'react';
import styles from './MensClothingBanner.module.css'; // Importing the modular CSS

const MensClothingBanner = () => {
  return (
    <div className={styles.container}>
      <div className={styles.textContainer}>
        <p className={styles.text}>Men's Clothing</p>
        <h2>All Products</h2>
      </div>
      <div className={styles.imageContainer}>
        <img src="images/MensClothingImg.jpg" alt="Placeholder" className={styles.image} />
      </div>
    </div>
  );
};

export default MensClothingBanner;

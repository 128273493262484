import React, { useEffect, useState, useCallback } from 'react';
import LoadingSpinner2 from '../components/LoadingSpinner2';
import Hero from '../components/shop/Hero';
import Cards from '../components/shop/Cards';
import Banner from '../components/shop/Banner';
import Cards2 from '../components/shop/Cards2';

const ShopPage = () => {
  const components = [<Hero />, <Cards />, <Banner />, <Cards2 />];
  const [visibleComponents, setVisibleComponents] = useState(2);
  const [loading, setLoading] = useState(false);

  const loadMoreComponents = useCallback(() => {
    if (visibleComponents < components.length) {
      setLoading(true);

      // Use requestAnimationFrame to ensure DOM updates have occurred
      setTimeout(() => {
        setVisibleComponents(prev => prev + 2);
        
        // Use requestAnimationFrame to wait for the next paint
        requestAnimationFrame(() => {
          // Add a small delay to ensure components have rendered
          setTimeout(() => {
            setLoading(false);
          }, 100);
        });
      }, 500);
    }
  }, [visibleComponents, components.length]);

  const handleScroll = useCallback(() => {
    const scrollableHeight = document.documentElement.scrollHeight;
    const currentScroll = window.innerHeight + window.scrollY;

    if (currentScroll + 25 >= scrollableHeight && !loading) {
      loadMoreComponents();
    }
  }, [loadMoreComponents, loading]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  return (
    <div>
      {components.slice(0, visibleComponents)}
      {loading && (
        <div style={{ 
          textAlign: 'center', 
          margin: '20px 0',
          // Add height to prevent layout shift
          height: '50px',  // Adjust based on your spinner height
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          <LoadingSpinner2 />
        </div>
      )}
    </div>
  );
};

export default ShopPage;
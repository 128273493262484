import React from 'react';
import styles from './Hero2.module.css';

const Hero2 = () => {
  return (
    <section className={styles.heroSection}>
      <div className={styles.textContainer}>
        <h1 className={styles.custom}> Electronics </h1>
        <p className={styles.neon}>All Products</p>
      </div>
      <div className={styles.imageContainer}>
        <img src='/images/ElectronicsImg.jpg' alt="Demo Neon Light" className={styles.demoImage} />
      </div>
    </section>
  );
};

export default Hero2;

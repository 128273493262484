import React from 'react';
import styles from './MensFootwearBanner.module.css'; // Importing the modular CSS

const MensFootwearBanner = () => {
  return (
    <div className={styles.container}>
      <div className={styles.textContainer}>
        <p className={styles.text}>Men's Footwear </p>
        <h2>Men's &gt; Footwear</h2>
      </div>
      <div className={styles.imageContainer}>
        <img src="/images/MensFootwearImg.jpg" alt="Placeholder" className={styles.image} />
      </div>
    </div>
  );
};

export default MensFootwearBanner;

import React, { useEffect, useState, useCallback } from 'react';
import styles from './AllProducts.module.css';
import SearchBar from './SearchBar';
import LoadingSpinner2 from '../LoadingSpinner2';

const slugify = (name) => {
  return name
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, '-')
    .replace(/(^-|-$)/g, '');
};

const BASE_URL = process.env.REACT_APP_BASE_PRODUCT_URL || 'https://myshopprime.com';

const generateProductLink = (name, id) => {
  const slug = slugify(name);
  return `${BASE_URL}/product/${slug}/${id}`;
};

const Card = React.memo(({ id, image, name, subtitle, new_price, rating }) => {
  const productLink = generateProductLink(name, id);

  return (
    <div className={styles.card}>
      <a href={productLink} target="_blank" rel="noopener noreferrer">
        <img src={image} alt={name} className={styles.cardImage} loading="lazy" />
        <h3 className={styles.cardTitle}>{name}</h3>
        <p className={styles.cardSubtitle}>{subtitle}</p>
        <div className={styles.cardPrice}>
          <p className={styles.ratingButton}>{rating} ⭐</p>
          <p className={styles.newPrice}>₹{new_price}</p>
        </div>
      </a>
    </div>
  );
});

const AllProducts = () => {
  const [popularItems, setPopularItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [displayCount, setDisplayCount] = useState(20); // Number of items to display initially
  const [loadingMore, setLoadingMore] = useState(false); // State to manage loading more items

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://affordly-backend.vercel.app';

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/allproducts`);
        if (!response.ok) throw new Error('Network response was not ok');
        const data = await response.json();
        setPopularItems(data);
        setFilteredItems(data);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleScroll = useCallback(() => {
    const lastCard = document.querySelector(`.${styles.cardContainer} > div:last-child`); // Get the last card
    if (!lastCard) return; // If there are no cards, do nothing

    const lastCardOffset = lastCard.getBoundingClientRect().bottom; // Get the last card's bottom position
    const pageOffset = window.innerHeight; // Get the current page height
    const bottomOffset = 5; // Define how close to the bottom we want to load more items

    // Check if we are near the bottom of the list
    if (lastCardOffset < pageOffset + bottomOffset && !loadingMore) {
      // Check if there are more items to load
      if (displayCount < filteredItems.length) {
        setLoadingMore(true);
        setTimeout(() => {
          setDisplayCount(prevCount => prevCount + 20); // Increase display count by 20
          setLoadingMore(false);
        }, 500); // Simulate loading delay
      }
    }
  }, [loadingMore, displayCount, filteredItems.length]); // Include dependencies

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]); // Include handleScroll here

  // Show loading spinner if still fetching data
  if (loading) {
    return <LoadingSpinner2 />;
  }

  // Show error message if there's an error
  if (error) {
    return <p>Error loading products</p>;
  }

  // Function to handle search filtering
  const handleSearch = (query) => {
    const normalizedQuery = query.toLowerCase().trim();
  
    // Function to handle plural forms (unchanged)
    const handlePluralization = (word) => {
      if (word.endsWith('s')) return word.slice(0, -1);
      return word;
    };
  
    // Function to handle possessive forms and gender prefixes (unchanged)
    const handlePossessiveAndGender = (word) => {
      const variations = new Set([word]);
      
      if (word.includes("'s")) {
        variations.add(word.replace("'s", "s"));
        variations.add(word.replace("'s", ""));
      } else if (word.endsWith('s')) {
        variations.add(word.slice(0, -1) + "'s");
      }
  
      const genderPrefixes = ['men', 'women', 'kids', 'boy', 'girl'];
      const isGenderTerm = genderPrefixes.some(prefix => word.startsWith(prefix));
  
      if (isGenderTerm) {
        const parts = word.match(/^(mens|womens|kids|boys|girls)(.*)/i);
        if (parts) {
          const [, prefix, item] = parts;
          variations.add(`${prefix.replace(/s$/, "'s")}${item}`);
          variations.add(`${prefix}${item}`);
          variations.add(`${prefix} ${item.trim()}`);
          variations.add(`${item.trim()} for ${prefix}`);
        }
      }
  
      return Array.from(variations);
    };
  
    // Enhanced function to handle variations with strict t-shirt matching
    const handleVariations = (word) => {
      const variations = new Set([word]);
  
      // Stricter t-shirt handling
      if (word === 'tshirt' || word === 't-shirt' || word === 't shirt') {
        variations.add('tshirt');
        variations.add('t-shirt');
        variations.add('t shirt');
      } else if (word === 'shirt' && !word.includes('t')) {
        variations.add('shirt');
        // Don't add t-shirt variations if specifically searching for shirts
      }
  
      const words = word.split(/[-\s]+/);
  
      if (words.length > 1) {
        variations.add(words.join('')); 
        variations.add(words.join('-')); 
        variations.add(words.join(' ')); 
      }
  
      variations.add(handlePluralization(word));
      handlePossessiveAndGender(word).forEach(v => variations.add(v));
  
      return Array.from(variations);
    };
  
    // Synonyms dictionary (unchanged)
    const synonyms = {
      "decoration": ["decorative", "decorative item", "decoration items", "home decor"],
      "decorative": ["decoration", "decorative item", "decoration items", "home decor"],
      "accessories": ["accessory", "belt", "belts", "bags", "watches", "jewelry"],
      "footwear": ["shoes", "sandals", "boots", "sneakers"],
      "clothing": ["clothes", "apparel", "wear", "fashion"],
      "men": ["mens", "men's", "masculine", "male"],
      "women": ["womens", "women's", "feminine", "female"],
      "kids": ["children", "children's", "childrens", "junior"],
    };
  
    // Function to get query variations (unchanged)
    const getQueryVariations = (word) => {
      const variations = new Set(handleVariations(word));
      
      const directSynonyms = synonyms[word] || [];
      directSynonyms.forEach(term => {
        variations.add(term);
        handleVariations(term).forEach(v => variations.add(v));
      });
  
      Object.entries(synonyms).forEach(([category, terms]) => {
        if (terms.includes(word)) {
          variations.add(category);
          handleVariations(category).forEach(v => variations.add(v));
        }
      });
  
      return Array.from(variations);
    };
  
    // Extract gender and item type from query
    const extractSearchParams = (query) => {
      const genders = ['men', 'mens', "men's", 'women', 'womens', "women's", 'kids', 'boys', 'girls'];
      const queryParts = query.split(/\s+/);
      
      let gender = null;
      let itemType = null;
      
      // Check for "for [gender]" pattern
      const forIndex = queryParts.indexOf('for');
      if (forIndex !== -1 && forIndex + 1 < queryParts.length) {
        const possibleGender = queryParts[forIndex + 1].replace(/'s$/, '').replace(/s$/, '');
        if (genders.includes(possibleGender)) {
          gender = possibleGender;
          itemType = queryParts.slice(0, forIndex).join(' ');
        }
      } else {
        // Check for "[gender] [item]" pattern
        const possibleGender = queryParts[0].replace(/'s$/, '').replace(/s$/, '');
        if (genders.includes(possibleGender)) {
          gender = possibleGender;
          itemType = queryParts.slice(1).join(' ');
        }
      }
  
      return { gender, itemType };
    };
  
    const { gender, itemType } = extractSearchParams(normalizedQuery);
  
    const filtered = popularItems.filter((item) => {
      const normalizedTitle = item.name.toLowerCase();
      const normalizedSubtitle = item.subtitle.toLowerCase();
      const normalizedCategory = (item.category || '').toLowerCase();
      const normalizedSubcategory = (item.subcategory || '').toLowerCase();
      const queryVariations = getQueryVariations(normalizedQuery);
  
      // Function to check if item matches the gender
      const matchesGender = (gender, itemData) => {
        const itemText = `${itemData.name} ${itemData.subtitle} ${itemData.category} ${itemData.subcategory}`.toLowerCase();
        return gender ? itemText.includes(gender) : true;
      };
  
      // Function to determine if item is a t-shirt
      const isTShirt = (itemData) => {
        const itemText = `${itemData.name} ${itemData.subtitle}`.toLowerCase();
        return itemText.includes('t-shirt') || itemText.includes('tshirt') || itemText.includes('t shirt');
      };
  
      // Check for t-shirt vs shirt specificity
      if (normalizedQuery.includes('shirt') && !normalizedQuery.includes('t')) {
        if (isTShirt(item)) return false;
      }
  
      const isMatch = queryVariations.some(queryVar => 
        normalizedTitle.includes(queryVar) ||
        normalizedSubtitle.includes(queryVar) ||
        normalizedCategory.includes(queryVar) ||
        normalizedSubcategory.includes(queryVar) ||
        (queryVar.includes(' ') && 
          ((normalizedCategory.includes(queryVar.split(' ')[0]) && 
            normalizedSubcategory.includes(queryVar.split(' ')[1])) ||
           (normalizedTitle.includes(queryVar.split(' ')[0]) && 
            normalizedTitle.includes(queryVar.split(' ')[1]))))
      );
  
      return isMatch && matchesGender(gender, item);
    });
  
    // Sort results to prioritize exact matches and gender-specific items
    const sortedFiltered = filtered.sort((a, b) => {
      const aText = `${a.name} ${a.subtitle} ${a.category} ${a.subcategory}`.toLowerCase();
      const bText = `${b.name} ${b.subtitle} ${b.category} ${b.subcategory}`.toLowerCase();
      
      // Prioritize gender-specific matches
      if (gender) {
        const aHasGender = aText.includes(gender);
        const bHasGender = bText.includes(gender);
        if (aHasGender && !bHasGender) return -1;
        if (!aHasGender && bHasGender) return 1;
      }
  
      // Prioritize exact item type matches
      if (itemType) {
        const aHasExactType = aText.includes(itemType);
        const bHasExactType = bText.includes(itemType);
        if (aHasExactType && !bHasExactType) return -1;
        if (!aHasExactType && bHasExactType) return 1;
      }
  
      return 0;
    });
  
    setFilteredItems(sortedFiltered);
    setDisplayCount(20);
  };
  
  


  return (
    <>
      <SearchBar onSearch={handleSearch} />
      <section className={styles.section}>
        <h1 className={styles.heading}>All Products</h1>
        <div className={styles.cardContainer}>
          {filteredItems.slice(0, displayCount).map((card) => (
            <Card
              key={card.id}
              id={card.id}
              image={card.image}
              name={card.name}
              subtitle={card.subtitle}
              rating={card.rating}
              new_price={card.new_price}
            />
          ))}
        </div>
        {loadingMore && (
          <div style={{ textAlign: 'center', margin: '20px 0' }}>
            <LoadingSpinner2 />
          </div>
        )}
        {filteredItems.length === 0 && <p>No items found</p>}
      </section>
    </>
  );
};

export default AllProducts;

import React from 'react';
import styles from './About.module.css';

const About = () => {
  return (
    <div className={styles.aboutContainer}>
      <div className={styles.aboutContent}>
        <h1 className={styles.heading}>About Us</h1>
        <p className={styles.description}>
          "Welcome to Affordly, designed to bring you the best products straight from our GlowRoad shop! Explore our curated selections, and enjoy a seamless shopping experience through our user-friendly interface".
        </p>

        <div className={styles.cardsContainer}>
          <div className={styles.card}>
            <h2 className={styles.subheading}>Our Mission</h2>
            <p className={styles.cardDescription}>
              We aim to connect you with quality products from our GlowRoad shop while providing a seamless experience. Whether you're looking for essentials or affordable items, we’ve got you covered.
            </p>
          </div>

          <div className={styles.card}>
            <h2 className={styles.subheading}>Why Choose Us?</h2>
            <ul className={styles.list}>
              <li className={styles.listItem}>Handpicked products for quality and style</li>
              <li className={styles.listItem}>Easy navigation and quick redirects to our GlowRoad shop</li>
              <li className={styles.listItem}>Safe and secure transactions on GlowRoad</li>
            </ul>
          </div>

          <div className={styles.card}>
            <h2 className={styles.subheading}>How It Works</h2>
            <p className={styles.cardDescription}>
              Browse our exclusive collections, find the perfect product, and click to be redirected to GlowRoad for a secure checkout. It’s that simple!
            </p>
          </div>
        </div>

        <div className={styles.ctaSection}>
          <h2 className={styles.ctaHeading}>Explore Our Full Collection</h2>
          <p className={styles.ctaDescription}>
            Discover our entire range of products. Whether you're looking for something unique or just browsing.
          </p>
          <a href="/allproducts"><button className={styles.ctaButton}>View All Products</button></a>
        </div>
      </div>
    </div>
  );
};

export default About;

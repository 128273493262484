// Banner.jsx
import React, { useState, useEffect } from 'react';
import styles from './Banner.module.css'; // Import the modular CSS


// Helper function to slugify the product name
const slugify = (name) => {
  return name
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, '-') // Replaces spaces and special characters with hyphens
    .replace(/(^-|-$)/g, ''); // Removes leading and trailing hyphens
};

// Get base product URL from environment variables
const BASE_URL = process.env.REACT_APP_BASE_PRODUCT_URL || 'https://myshopprime.com';

// Helper function to generate the full URL based on the base URL, slug, and id
const generateProductLink = (name, id) => {
  const slug = slugify(name);
  return `${BASE_URL}/product/${slug}/${id}`;
};

const Card = React.memo(({ image, id, name }) => {
  const productLink = generateProductLink(name, id); // Generate the complete link

  return (
    <div>
      <a href={productLink} target="_blank" rel="noopener noreferrer" aria-label={`View ${name} details`}>
        <img src={image} alt={`Product img of ${name}`} className={styles.logo} loading="lazy" /> {/* Meaningful alt attribute */}
      </a>
    </div>
  );
});

const Banner = () => {
  const [offerItems, setOfferItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://affordly-backend.vercel.app';

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/offeritems`);
        if (!response.ok) throw new Error('Network response was not ok');
        const data = await response.json();
        setOfferItems(data);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(true); // Set error state to true
      } finally {
        setLoading(false); // Always set loading to false after fetching
      }
    };

    fetchData();
  }, []);

  // Conditional rendering: show spinner if loading or if there's an error
  if (loading || error) {
    return null;
  }

  return (
    <section className={styles.banner}>
      <div className={styles.textContainer}>
        <h1 className={styles.title}>Top Rated Products</h1>
        <h2 className={styles.subtitle}>Shop Now</h2> {/* Use h2 for subheading */}
      </div>
      <div className={styles.imageContainer}>
        <div className={styles.logoContainer}>
          {offerItems.map((card) => (
            <Card
              key={card.id} // Ensure key is set correctly
              id={card.id} // Pass id to Card
              image={card.image}
              name={card.name}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Banner;

import React, { useState, useEffect } from 'react';
import TypingEffect from 'react-typing-effect';
import styles from './Hero.module.css';


const images = [
  {
    src: "/new-arrivals-images/ImgSlide1.webp",
    link: "https://myshopprime.com/product/msp-slug/1693687572" // Replace with the actual link
  },
  {
    src: "/new-arrivals-images/ImgSlide2.webp",
    link: "https://myshopprime.com/product/stylish-fancy-cotton-blend-straight-kurta-for-men/1693687148" // Replace with the actual link
  },
  {
    src: "/new-arrivals-images/ImgSlide3.webp",
    link: "https://myshopprime.com/product/daily-wear-lycra-saree-with-blouse-piece/1693702258" // Replace with the actual link
  },
  {
    src: "/new-arrivals-images/ImgSlide4.webp",
    link: "https://myshopprime.com/product/sws-90-ft-approx-multi-colour-rice-lights-serial-bulb-decoration-light-with-remote-function-for-diwali-navratra-christmas/1693905601" // Replace with the actual link
  },
];

// Helper function to slugify the product name
const slugify = (name) => {
  return name
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, '-')
    .replace(/(^-|-$)/g, '');
};

// Get base product URL from environment variables
const BASE_URL = process.env.REACT_APP_BASE_PRODUCT_URL || 'https://myshopprime.com';

// Helper function to generate the full URL based on the base URL, slug, and id
const generateProductLink = (name, id) => {
  const slug = slugify(name);
  return `${BASE_URL}/product/${slug}/${id}`;
};

const Card = React.memo(({ id, image, name, subtitle, new_price, rating }) => {
  const productLink = generateProductLink(name, id); // Generate the complete link

  return (
    <div className={styles.card}>
      <a href={productLink} target="_blank" rel="noopener noreferrer">
        <img src={image} alt={name} className={styles.cardImage} loading="lazy" />
        <h3 className={styles.cardTitle}>{name}</h3>
        <p className={styles.cardSubtitle}>{subtitle}</p>
        <div className={styles.cardPrice}>
          <p className={styles.ratingButton}>{rating} ⭐</p>
          <p className={styles.newPrice}>₹{new_price}</p>
        </div>
      </a>
    </div>
  );
});

const Hero = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showCards, setShowCards] = useState(false);

  const [heroItems, setHeroItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const goToPrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const goToNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  const handleCheckNowClick = () => {
    setShowCards((prevShowCards) => !prevShowCards);// Show the cards
  };

  // This useEffect runs after `showCards` changes to true and triggers the scroll to the element
  useEffect(() => {
    if (showCards) {
      const showCardsSection = document.getElementById('showCards');
      if (showCardsSection) {
        showCardsSection.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [showCards]);

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://affordly-backend.vercel.app';

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/heroitems`);
        if (!response.ok) throw new Error('Network response was not ok');
        const data = await response.json();
        setHeroItems(data);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(true); // Set error state to true
      } finally {
        setLoading(false); // Always set loading to false after fetching
      }
    };

    fetchData();
  }, []);

  // Conditional rendering: show spinner if loading or if there's an error
  if (loading || error) {
    return null;
  }

  return (
    <>
      <section className={styles.hero}>
        <div className={styles.heroText}>
          <h1>New Collections For Everyone</h1>
          <TypingEffect
            text={['NEW ARRIVALS ONLY!!']}
            speed={50}
            eraseSpeed={25}
            eraseDelay={1000}
            typingDelay={500}
            className={styles.typingEffect}
          />
          <div>
            <button className={styles.ctaButton} onClick={handleCheckNowClick}>
              Check Now
            </button>
          </div>
        </div>
        <div className={styles.heroImage}>
          <div className={styles.imageSlider}>
            <a href={images[currentIndex].link} target="_blank" rel="noopener noreferrer">
              <img src={images[currentIndex].src} alt="Hero" className={styles.sliderImage} />
            </a>
            <button className={styles.prevButton} onClick={goToPrevious}>‹</button>
            <button className={styles.nextButton} onClick={goToNext}>›</button>
          </div>
        </div>
      </section>

      {showCards && (
        <section id="showCards" className={styles.section}>
          <h1 className={styles.heading}>New Arrivals</h1>
          <div className={styles.cardContainer}>
            {heroItems.length > 0 ? (
              heroItems.map((card) => (
                <Card
                  key={card.id} // Ensure key is set correctly
                  id={card.id} // Pass id to Card
                  image={card.image}
                  name={card.name}
                  subtitle={card.subtitle}
                  rating={card.rating}
                  new_price={card.new_price}
                />
              ))
            ) : (
              <p>No items found</p>
            )}
          </div>
        </section>
      )}
    </>
  );
};

export default Hero;

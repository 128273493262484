import React from 'react';
import styles from './Footer.module.css';

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.container}>
        <div className={styles.logo}>
          <img src="/logo2.png" alt="Logo" className={styles.logoImage} />
        </div>
        <nav className={styles.navMenu}>
          <a href="/about" className={styles.navLink}>About</a>
          <a href="/contact" className={styles.navLink}>Contact</a>
        </nav>
        <div className={styles.socialIcons}>
          <a href="https://www.instagram.com/affordly_store/profilecard/?igsh=MTM3Y2p6NmVwaDUxZw==" className={styles.socialLink} aria-label="Instagram" target="_blank" rel="noreferrer">
          <i class="fa-brands fa-instagram"></i>
          </a>
          <a href="https://www.facebook.com/profile.php?id=100063563546223&mibextid=ZbWKwL" className={styles.socialLink} aria-label="Facebook" target="_blank" rel="noreferrer">
          <i class="fa-brands fa-facebook"></i>
          </a>
        </div>
      </div>
      <hr className={styles.divider} />
      <p className={styles.copyright}>© 2024 Affordly. All rights reserved.</p>
    </footer>
  );
};

export default Footer;

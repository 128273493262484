import React, { useState } from 'react';
import styles from './SearchBar.module.css';
import DOMPurify from 'dompurify'; // For sanitizing input

const SearchBar = ({ onSearch }) => {
  const [query, setQuery] = useState('');

  // Sanitize the user input to prevent XSS attacks
  const sanitizeInput = (input) => {
    const cleanInput = DOMPurify.sanitize(input);
    return cleanInput;
  };

  // Update the query as the user types
  const handleInputChange = (e) => {
    const sanitizedQuery = sanitizeInput(e.target.value);  // Sanitize user input
    setQuery(sanitizedQuery);  // Update the local query state
  };

  // Handle search button click or Enter key press
  const handleSearchClick = () => {
    if (query.trim()) {
      const sanitizedQuery = sanitizeInput(query.trim());  // Sanitize again before sending to the parent
      onSearch(sanitizedQuery);  // Pass the sanitized and trimmed query to the parent
    } else {
      onSearch('');  // If the query is empty, show all products
    }
  };

  // Trigger search when Enter key is pressed
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSearchClick();  // Call search when Enter is pressed
    }
  };

  return (
    <div className={styles.searchBarContainer}>
      <input
        type="text"
        value={query}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}  // Add this event to listen for "Enter" key press
        maxLength={100}  // Limit the length of the input to 100 characters
        placeholder="Search for products..."
        className={styles.searchInput}
      />
      <button onClick={handleSearchClick} className={styles.searchButton}>
        Search
      </button>
    </div>
  );
};

export default SearchBar;

import React from 'react';
import styles from './Contact.module.css';

const Contact = () => {
  return (
    <section className={styles.contact}>
      {/* Social Media Section */}
      <div className={styles.socialMediaSection}>
        <h2>Connect With Us</h2>
        <div className={styles.socialMediaLinks}>
          <a href="https://www.instagram.com/affordly_store/profilecard/?igsh=MTM3Y2p6NmVwaDUxZw==" target="_blank" rel="noreferrer">
            <i className="fa-brands fa-instagram"></i> Instagram
          </a>
          <a href="https://www.facebook.com/profile.php?id=100063563546223&mibextid=ZbWKwL" target="_blank" rel="noreferrer">
            <i className="fa-brands fa-facebook"></i> Facebook
          </a>
        </div>
      </div>

      {/* Existing Contact Section */}
      <div className={styles.contactSection}>
        <div className={styles.contactFormCard}>
          <h2>E-mail Us</h2>
          <form action="https://api.web3forms.com/submit" method="POST">
            <input type="hidden" name="access_key" value="74e22028-1cac-405a-b67e-8c242851d6a8" />
            <input type="text" name="name" placeholder="Your Name" required />
            <input type="text" name="message" placeholder="Your Message" required />
            <button className={styles.sendButton} type="submit">Send E-mail</button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Contact;
